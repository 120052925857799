import React, { useRef, useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CircularJSON from 'circular-json';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from './Card';
import '../styles/Inscription.css';


function Inscription(){
    let navigate = useNavigate()
    let dispatch = useDispatch()

    const [mdp, setMdp] = useState('')
    const [verifMdp,setVerifMdp] = useState('')
    const [mdpEgaux,setMdpEgaux] = useState(true)
    const [message,setMessage] = useState('')
    const [couleurMessage,setCouleurMessage] = useState('red')

    const formRef = useRef(null);

    const verifMdpStyle = {
        borderColor: mdpEgaux ? '#ccc' : 'red',
      };

    function handleMdpChange(e){
        setMdp(e.target.value)
    }

    function handleVerifMdpChange(e){
        setVerifMdp(e.target.value)
    }

    const goConnexion = () => {
        dispatch({type:'menu/pageChanged',payload:{id:5,text:'connexion'}})
        navigate('/Connexion')
    }

    function handleSubmit (event) {
        event.preventDefault()

        if(!mdpEgaux)
            return;

        const hashedPassword = mdp;
        var connectionFailed = false

        fetch(`${process.env.REACT_APP_URL_API}/utilisateur/creation`,{
            method: 'POST',
            body: CircularJSON.stringify({
                email: event.target.elements.email.value,
                pseudo: event.target.elements.pseudo.value,
                mdp: hashedPassword
            },),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((reponse) => {
            console.log(reponse)
            if(!reponse.ok) {
                return reponse.json().then(reponse => { throw new Error(reponse.error) })
            }
            else {
                return reponse.json();
            }    
        })
        .then((resultat) =>{
            setCouleurMessage('green')
            setMessage(resultat.Message)
            formRef.current.reset();
        })
        .catch(err => {
            setCouleurMessage('red')
            console.log(err)
            console.log(JSON.parse(err.Error))         
            setMessage(err.Message)
            throw new Error("Erreur d'authentification");
         });
    }

    useEffect(()=>{
        if(mdp === verifMdp)
            setMdpEgaux(true)
        else
            setMdpEgaux(false)
    },[mdp,verifMdp])

    return(
        <div className='Inscription'>
            <Card className='CardForm'>
                <form onSubmit={handleSubmit} ref={formRef} className='FormInscription'>
                    <div className='input-container'>
                        <div className='titre'> E-mail : </div>   
                        <div className='article-input-container'>
                            <input required className='article-input input-style' type='email' name='email' maxLength='50'/>
                        </div>           
                    </div>
                    
                    <div className='phrase-confirmer'>
                        Vous devrez confirmer votre e-mail.
                    </div>
                    <div className='input-container'>
                        <div className='titre'> Pseudo : </div>   
                        <div className='article-input-container'>
                            <input required className='article-input input-style' type='text' name='pseudo' maxLength='30'/>
                        </div>
                    </div>

                    <div className='input-container'>
                        <div className='titre'> Mot de passe : </div>   
                        <div className='article-input-container'>
                            <input required className='article-input input-style' type='password' name='mdp' maxLength='30'  minLength="8" onChange={handleMdpChange} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Le mot de passe doit au moins contenir 1 lettre majuscule, 1 chiffre et 1 lettre minuscule"/>
                        </div>
                    </div>

                    <div className='input-container'>
                        <div className='titre'> Confirmation : </div>   
                        <div className='article-input-container'>
                            <input required className='article-input input-style' type='password' name='mdp' maxLength='30'  minLength="8" onChange={handleVerifMdpChange} style={verifMdpStyle}/>
                        </div>
                    </div>

                    <div className='boutons-inscription'>
                        <input className='bouton-inscription' type="submit" value='Inscription' />
                        <div className='DejaCompte-container'>
                            <a onClick={goConnexion} className='DejaCompte'>J'ai déjà un compte</a>
                        </div>
                        
                    </div>
                    <div className='MessageInfo' style={{ color: couleurMessage }}>
                        {message}
                    </div>
                </form>
            </Card>
        </div>
    )
}



export default Inscription
const initialState = {
    etatMenu : {id:1,text:'ouvert'},
    pageAffiche : {id1:1,text:'accueil'},
    etatConnexion : {id:0,text:'deconnecte'}
}

function menuReducer(state = initialState,action){
    switch(action.type){
        case 'menu/etatMenuChanged':{
            return {
                ...state,
                etatMenu: action.payload
            }
        }
        case 'menu/pageChanged':{
            return {
                ...state,
                pageAffiche: action.payload
            }
        }
        case 'menu/etatConnexionChanged':{
            return {
                ...state,
                etatConnexion: action.payload
            }
        }
        default:{
            return {
                ...state
            }
        }
    }
}

export default menuReducer;
const initialState = {
    sections:[],
    sectionsAttente:[]
}

function nextTodoId(sections) {
    const maxId = sections.reduce((maxId, section) => Math.max(section.id, maxId), -1)
    return maxId + 1
}

function sectionsReducer(state = initialState,action){
    switch(action.type){
        case 'sectionsAttente/ajout':{
            return {
                sections:state.sections,
                sectionsAttente:[
                    ...state.sectionsAttente,
                    {
                        id: nextTodoId(state.sectionsAttente)
                    }
                ]                
            }            
        }
        case 'sectionsAttente/supression': {
            return {
                sections:state.sections,
                sectionsAttente:state.sectionsAttente.filter((section) => section.id !== action.payload.id)   
            }   
            
        }
        case 'sections/ajout':{
            return {
                sectionsAttente:state.sectionsAttente,
                sections:[
                    ...state.sections,
                    {
                        id: nextTodoId(state.sections),
                        titre: action.payload.titre,
                        texte: action.payload.texte
                    }
                ]                
            }                         
        }
        case 'sections/supression': {
            return {
                sectionsAttente:state.sectionsAttente,
                sections:state.sections.filter((section) => section.id !== action.payload.id)   
            }   
            
        }
        case 'sections/reset':{
            return initialState
        }
        default:{
            return state
        }
    }
}

export default sectionsReducer
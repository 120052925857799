import '../styles/NavSide.css'
import { createPath, useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';
import React, {  useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import iconHome from '../images/home.png'
import iconConnexion from '../images/log-in.png'
import iconInscription from '../images/sign-in.png'
import iconProfil from '../images/user.png'
import iconListe from '../images/clipboard.png'
import iconDeconnexion from '../images/log-out.png'
import iconAdd from '../images/add.png'
import iconNotification from '../images/notification.png'
import ClientWS from '../Client';

import Modal from './Modal'

const clientWS = ClientWS.getInstance();

function NavSide(){
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const [pseudo,setPseudo]= useState('')
    const [nombreNotifications,setNombreNotifications] = useState(0)
    const [voirModalDeconnexion,setVoirModalDeconnexion] = useState(false)

    useEffect(() => {

        function handleResize() {
            if( window.innerWidth > 800){
                dispatch({type:'menu/etatMenuChanged',payload:{id:1,text:'ouvert'}})
            }
            else{
                dispatch({type:'menu/etatMenuChanged',payload:{id:0,text:'ferme'}})
            }
        }
    
        window.addEventListener('resize', handleResize)

        if( window.innerWidth < 800){
            dispatch({type:'menu/etatMenuChanged',payload:{id:0,text:'ferme'}})
        }
        
    },[])

    const pageAffiche = useSelector(state => state.menu.pageAffiche)
    const etatMenu = useSelector(state => state.menu.etatMenu)
    const etatConnexion = useSelector(state => state.menu.etatConnexion)

    useEffect(() => {
        clientWS.getWs().onmessage = (message) => {
            let messageJson = JSON.parse(message.data)
            if(messageJson.msg === 'Notification' && messageJson.idUtilisateur === Cookie.get('idUtilisateur')){
                recupNotifatications()
            }
        }

        if(etatConnexion.id === 1){
            recupNotifatications()
        }
    })

    function recupNotifatications(){
        fetch(`${process.env.REACT_APP_URL_API}/notifications/nombre`,{
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer '+ Cookie.get('jwt')
            },
        }).then((reponse) => {
            if (reponse.status === 401) {
                throw new Error("Erreur d'authentification")               
            }
            return reponse.json()})
        .then((resultat) =>{
            setNombreNotifications(resultat.count)
        })   
    }

    useEffect(() => {
        if(etatConnexion.id === 1){
            recupNotifatications()
        }
    },[etatConnexion])

    const handleClickHome = ()=> {
        navigate('/')
        dispatch({type:'menu/pageChanged',payload:{id:1,text:'accueil'}})
        if( window.innerWidth < 800){
            dispatch({type:'menu/etatMenuChanged',payload:{id:0,text:'ferme'}})
        }
    }

    const handleClickEvents = () =>{
        navigate('/Evenements')
        dispatch({type:'menu/pageChanged',payload:{id:2,text:'evenements'}})
        if( window.innerWidth < 800){
            dispatch({type:'menu/etatMenuChanged',payload:{id:0,text:'ferme'}})
        }
    }

    const handleClickAjout = () =>{
        navigate('/Ajout-article')
        dispatch({type:'menu/pageChanged',payload:{id:3,text:'ajout'}})
        if( window.innerWidth < 800){
            dispatch({type:'menu/etatMenuChanged',payload:{id:0,text:'ferme'}})
        }
    }

    const handleClickInscription = ()=> {
        navigate('/Inscription')
        dispatch({type:'menu/pageChanged',payload:{id:4,text:'inscription'}})
        if( window.innerWidth < 800){
            dispatch({type:'menu/etatMenuChanged',payload:{id:0,text:'ferme'}})
        }
    }

    const handleClickConnexion = ()=> {
        navigate('/Connexion')
        dispatch({type:'menu/pageChanged',payload:{id:5,text:'connexion'}})
        if( window.innerWidth < 800){
            dispatch({type:'menu/etatMenuChanged',payload:{id:0,text:'ferme'}})
        }
    }

    const handleClickProfil = () => {
        navigate('/Profil')
        dispatch({type:'menu/pageChanged',payload:{id:6,text:'profil'}})
        if( window.innerWidth < 800){
            dispatch({type:'menu/etatMenuChanged',payload:{id:0,text:'ferme'}})
        }
    }

    const handleClickNotification = () =>{
        navigate('/Notifications')
        dispatch({type:'menu/pageChanged',payload:{id:7,text:'notifications'}})
        if( window.innerWidth < 800){
            dispatch({type:'menu/etatMenuChanged',payload:{id:0,text:'ferme'}})
        }
    }

    const handleClickDeconnexion = () => {
       setVoirModalDeconnexion(true)
    }

    const handleFermerModalDeconnexion = () => {
        setVoirModalDeconnexion(false)
    }

    function deconnexion(){
        setVoirModalDeconnexion(false)
        Cookie.remove('jwt')
        Cookie.remove('pseudo')
        dispatch({type:'menu/etatConnexionChanged',payload:{id:0,text:'deconnecte'}})
        setPseudo('')
        handleClickConnexion()
    }

   const handleCloseMenu = () => {
        dispatch({type:'menu/etatMenuChanged',payload:{id:0,text:'ferme'}})
   }

    useEffect(() =>{
        const cookieValue = Cookie.get('pseudo');
        if (cookieValue) {
           
            setPseudo(cookieValue.pseudo)
            dispatch({type:'menu/etatConnexionChanged',payload:{id:1,text:'connecte'}})
            
        } else {
            console.log('Le cookie n\'existe pas.');
        }
    },[])

    return(
        <div className='NavSide-overlay'>
            <div className={etatMenu.id === 1 ? 'overlay' : ''} onClick={handleCloseMenu}></div>
            <Modal show={voirModalDeconnexion}>
                <Modal.Titre>Déconnexion</Modal.Titre>
                <Modal.Body>
                    <div>Êtes-vous sûr de vouloir vous déconnecter ?</div>
                    <div className='boutons-modal'>
                        <div className='bouton-modal boutonModalFermer' onClick={handleFermerModalDeconnexion}>
                            Fermer
                        </div>
                        <div className='bouton-modal boutonModalConnecter' onClick={deconnexion}>
                            Se déconnecter
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        <div className="NavSide" style={{width:etatMenu.id === 1 ? '250px': '0px' }}>         
            <ul className='MenuNav'>
                <li>
                    <ul className='PagesRedirectionNav'>
                        <li className={ pageAffiche.id === 1 ? 'elem-nav-side elem-select': 'elem-nav-side'} onClick={handleClickHome}>
                            <img src={iconHome} className='icon-nav' alt='home'></img>
                            Accueil
                        </li>
                        <li className={ pageAffiche.id === 2 ? 'elem-nav-side elem-select': 'elem-nav-side'}  onClick={handleClickEvents}>
                            <img src={iconListe} className='icon-nav' alt='events'></img>
                            Évènements
                        </li>
                        <li className={ pageAffiche.id === 3 ? 'elem-nav-side elem-select': 'elem-nav-side'} onClick={handleClickAjout}>
                            <img src={iconAdd} className='icon-nav' alt='add'></img>
                            Ajouter
                        </li>
                    </ul>
                </li>
                <hr/>
                    {etatConnexion.id === 1 ?
                        <li className='ConnecteNav'>
                            <ul>
                                <li className={ pageAffiche.id === 6 ? 'elem-nav-side elem-select': 'elem-nav-side'} onClick={handleClickProfil}>
                                    <img src={iconProfil} className='icon-nav' alt='profil' ></img>
                                    Profil
                                </li>
                                <li className={ pageAffiche.id === 7 ? 'elem-nav-side elem-select': 'elem-nav-side'} onClick={handleClickNotification}>
                                    <img src={iconNotification} className='icon-nav' alt='notification' ></img>
                                    Notifications
                                    {nombreNotifications > 0 ? <div className='NotificationsAlertes'>{nombreNotifications}</div> : <div></div>}
                                    
                                </li>
                                <li className={ pageAffiche.id === 8 ? 'elem-nav-side elem-select': 'elem-nav-side'} onClick={handleClickDeconnexion}>
                                    <img src={iconDeconnexion} className='icon-nav' alt='deconnexion'></img>
                                    Déconnexion
                                </li>
                            </ul>
                        </li>
                        :
                        <li className='NonConnecteNav'>
                            <ul>
                                <li className={ pageAffiche.id === 4 ? 'elem-nav-side elem-select': 'elem-nav-side'} onClick={handleClickInscription}>
                                    <img src={iconInscription} className='icon-nav' alt='inscription'></img>
                                    Inscription
                                </li>
                                <li className={ pageAffiche.id === 5 ? 'elem-nav-side elem-select': 'elem-nav-side'} onClick={handleClickConnexion}>
                                    <img src={iconConnexion} className='icon-nav' alt='connexion'></img>
                                    Connexion
                                </li>
                            </ul>
                        </li>
                    }  
            </ul> 
        </div>
        </div>
    )
}

export default NavSide;
import '../styles/Modal.css'
import Card from './Card'

function Modal({children, className,show,onHide}){
    if(show){
        return(
            <div className={`my-modal`}>
                <div className='my-modal-overlay'></div>
                <Card className={`my-modal-modal ${className}`}>
                    {children}
                </Card>
            </div>
        )
    }
    else{
        return(
            <></>
        )
    }
}

Modal.Body = function ModalBody({ children,className }) {
    return (<div className={`my-modal-body ${className}`}>{children}</div>)
};


Modal.Titre = function ModalTitre({ children, className }) {
    const content = <div className={`my-modal-titre ${className}`}>{children}</div>;
    return (
        <>
          {content}
        </>
    )
};

export default Modal
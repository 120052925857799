import { combineReducers,applyMiddleware } from "redux";

import menuReducer from "./menuReducer";
import sectionsReducer from "./sectionsReducer";


const reducer = combineReducers({
    menu : menuReducer,
    sections: sectionsReducer,
})

export default reducer
import http from "./BaseAPI";

const getCompetitions = () =>{
    return http.get("/competitions/valide");
}

const getCompetition = (id) =>{
    return http.get(`/competition?idCompetition=${id}`);
}

const ServiceCompetitions = {
    getCompetitions,
    getCompetition
}

export default ServiceCompetitions;
import { useLocation,useNavigate } from 'react-router-dom';
import { useRef,useEffect,useState } from 'react';
import { useDispatch } from 'react-redux';

import '../styles/Verification.css'

function Verification(){
    const queryParameters = new URLSearchParams(window.location.search)
    let navigate = useNavigate()
    let dispatch = useDispatch()

    const [messageValidation,setMessageValidation] = useState('')
    const [couleurMessage,setCouleurMessage] = useState('red')
    const id = queryParameters.get("id")

    const goConnexion = () => {
        dispatch({type:'menu/pageChanged',payload:{id:5,text:'connexion'}})
        navigate('/Connexion')
    }

    useEffect(()=>{
        validation(id)
    },[id])

    function validation(idUtilisateur) {
        fetch(`${process.env.REACT_APP_URL_API}/utilisateur/validation?id=${idUtilisateur}`,{
            method: 'PUT',
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            },
        })
        .then((reponse) => {
            console.log("lala")
            if (!reponse.ok) {
                setCouleurMessage('red')
                setMessageValidation("Erreur lors de la validation du compte")
                throw new Error("Erreur d'authentification")               
            }
            return reponse.json()})
        .then((resultat) =>{
            setCouleurMessage('green')
            setMessageValidation("Votre compte à bien été validé")
        })   
    }

    return(
        <div className='Verification'>
            <div className='validation-container'>
                <div className='phrase-validation' style={{ color: couleurMessage }}>{messageValidation}</div>
                <div className='bouton-connexion' onClick={goConnexion}>Se connecter</div>
            </div>
        </div>
    )
}

export default Verification
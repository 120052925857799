import { useEffect, useState } from "react";
import ServiceCompetitions from "../services/ServiceCompetitions"
import Card from './Card';
import { useNavigate } from 'react-router-dom';
import '../styles/Evenement.css'

function Evenement(){
    let navigate = useNavigate();

    const [competitions,setCompetitions] = useState([])

    useEffect(()=>{
        ServiceCompetitions.getCompetitions().then(response => {
            setCompetitions(response.data)
          })
    },[])
    
    function dateFormat(date){
        var d = new Date(date); 
        return d.toLocaleString()
    }

    return(      
            <div className="EvenementArticles">
                <div className= "ListeArticles">
                    {competitions.map((c)=>{
                        c = c.competition
                        return( 
                            <div className="ArticleHover">
                                <Card className="CardArticles text-center"
                                    onClick={(e) =>{
                                        navigate('/Article',{state:{idCompetition:c.IdCompetition}})
                                    }}  
                                    key={c.IdCompetition}>
                                    <Card.Body>
                                        <div className="body-card">
                                            <div className="titre-card">{c.Titre}</div>
                                            <div className="texte-card">
                                                Date : {dateFormat(c.DateCompetition)} <br/>
                                                Localisation : {c.Localisation}
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>  
                            </div>            
                        )                
                    })}
                </div>
            </div>
    )
}

export default Evenement;
import '../styles/Card.css'

function Card({ children, className, onClick }){
    return(
        <div className={`my-card ${className}`} onClick={onClick}>
            {children}
        </div>
    )
}

Card.Body = function CardBody({ children }) {
    return children;
};

export default Card
import {applyMiddleware, createStore} from 'redux'
import reducer from './reducer'
import { composeWithDevTools} from 'redux-devtools-extension'

const composedEnhancer = composeWithDevTools(
    applyMiddleware()
)

const store = createStore(reducer,composedEnhancer)

export default store
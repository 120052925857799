import '../styles/AjoutArticle.css'

import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation,useNavigate } from 'react-router-dom';

import iconCalendrier from '../images/calendar.png'
import iconMap from '../images/map.png'
import iconSiteweb from '../images/website.png'
import iconPlus from '../images/plus.png'
import iconPoubelle from '../images/bin.png'
import iconModif from '../images/edit.png'

import CircularJSON from 'circular-json';
import Cookie from 'js-cookie';
import Modal from './Modal';
import Card from './Card'


function AjoutSectionDesc(props){
    return(
        <div className='AjoutSecionDescContainer'>
            <div className='AjoutSectionDesc' onClick={props.fonctionOnClick}>
                <div>Ajouter une section</div>
                <div className='IconPlusContainer'><img className='IconPlus' src={iconPlus} alt='plus' /></div>
            </div>
        </div>
    )
}

function SectionAttenteDesc(props){
    let dispatch = useDispatch()
    const [titre,setTitre] = useState('')
    const [texte,setTexte] = useState('')

    const handleSubmit = () =>{
        dispatch({type:'sections/ajout',payload:{titre:titre,texte:texte}})
        dispatch({type:'sectionsAttente/supression',payload:{id:props.id}})     
    }

    const handleSuprimer = () =>{
        dispatch({type:'sectionsAttente/supression',payload:{id:props.id}})  
    }

    const handleTitreChanged = (event) =>{
        setTitre(event.target.value)
    }

    const handleTexteChanged = (event) => {
        setTexte(event.target.value)
    }

    return(
        <div className='SectionDescContainer'>
            <div className='SectionDesc'>
                <div >
                    <div className='titre-section-container'>
                        <label className='titre-section-label' for='titre'>Titre de la section :</label>
                        <input className='titre-section-input input-style' name='titre' type='text' maxLength='50' required onChange={handleTitreChanged}/>
                    </div>
                    <div className='texte-section-container'>
                        <label className='texte-section-label' for='texte'>Contenue de la section :</label>
                        <textarea className='texte-section-input input-style' name='texte' maxLength='400' rows='4' required onChange={handleTexteChanged}/>
                    </div>
                    <div className='bouton-section-attente-container'>
                        <div >
                            <input className='bouton-section-attente' type='button' value='Valider la section' onClick={handleSubmit} />
                        </div>
                        <div className='icon-section-attente-container' onClick={handleSuprimer}>
                            <img className='icon-section' src={iconPoubelle} alt='poubelle' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function SectionDesc(props){
    let dispatch = useDispatch()

    const [titre,setTitre] = useState(props.titre)
    const [texte,setTexte] = useState(props.texte)

    const handleSuprimer = () =>{
        dispatch({type:'sections/supression',payload:{id:props.id}})  
    }

    return(
        <div className='SectionDescContainer'>
            <div className='SectionDesc'>
                <div className='titre-section-container'>
                    <div className='titre-section-label' >Titre de la section :</div>
                    <div className='titre-section' > {titre}</div>
                </div>
                <div className='texte-section-container'>
                    <div className='texte-section-label'>Contenue de la section :</div>
                    <div className='texte-section' >
                        <code>{texte}</code>
                    </div>
                </div>
                <div className='icons-section-container'>
                    {/*<div className='icon-section-container'>
                        <img className='icon-section' src={iconModif} alt='modif' />
                    </div>*/}
                    <div className='icon-section-container' onClick={handleSuprimer}>
                        <img className='icon-section' src={iconPoubelle} alt='poubelle' />
                    </div>
                </div>
            </div>
        </div>  
    )
}

function AjoutArticle(){
    //
    let dispatch = useDispatch()
    let navigate = useNavigate()

    const sectionsValide = useSelector(state => state.sections.sections)
    const sectionsAttente = useSelector(state => state.sections.sectionsAttente)

    const [voirModalConnexion,setVoirModalConnexion] = useState(false)
    const [voirModalConfirmation,setVoirModalConfirmation] = useState(false)

    const refFormPrincipal = useRef(null)

    const handleClickAjoutSection = () =>{
       dispatch({type:'sectionsAttente/ajout',payload:{}})
    }

    const handleSubmit = (event) =>{
        event.preventDefault()
        let titre = event.target.elements.titre.value
        let date = event.target.elements.date.value
        let localisation = event.target.elements.localisation.value
        let site = event.target.elements.site.value
        fetch(`${process.env.REACT_APP_URL_API}/competition/creation`,{
            method: 'POST',
            body: CircularJSON.stringify({
                titre:titre,
                date: date,
                localisation: localisation,
                site:site,
                commentaires:sectionsValide
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer '+ Cookie.get('jwt')
            },
        }).then((reponse) => {
            if (reponse.status === 401) {
                setVoirModalConnexion(true)
                throw new Error("Erreur d'authentification")               
            }
            return reponse.json()})
        .then((resultat) =>{
            setVoirModalConfirmation(true)
        })   
    }

    const handleFermerModalConnexion = () => {
        setVoirModalConnexion(false)
    }

    const handleFermerModalConfirmation = () => {
        setVoirModalConfirmation(false)
        dispatch({type:'sections/reset',payload:{}})
        refFormPrincipal.current.reset()
    }

    const handleAllerPageConnexion = () =>{
        dispatch({type:'menu/pageChanged',payload:{id:5,text:'connexion'}})
        navigate('/Connexion')
    }

    return(
            <div className='AjoutArticle'> 
                <Modal show={voirModalConnexion}>
                    <Modal.Titre>Vous n'êtes pas connecter</Modal.Titre>
                    <Modal.Body>
                        Vous devez être connecter pour envoyer un message
                        <div className='boutons-modal'>
                            <div className='bouton-modal boutonModalFermer' onClick={handleFermerModalConnexion}>
                                Fermer
                            </div>
                            <div className='bouton-modal boutonModalConnecter' onClick={handleAllerPageConnexion}>
                                Se connecter
                            </div>
                        </div>
                        
                    </Modal.Body>
                </Modal>
                <Modal show={voirModalConfirmation} >
                        <Modal.Titre>Confirmation envoi</Modal.Titre>
                        <Modal.Body>
                            <div className='texte-modal'>
                                Votre article a bien été envoyé.
                                Un administrateur le validera avant l'ajout définitif.
                            </div>
                            <div className='boutons-modal'>
                                <div className='bouton-modal boutonModalConnecter' onClick={handleFermerModalConfirmation}>
                                    Ok
                                </div>
                            </div>
                        </Modal.Body>
                    
                </Modal>
                <Card className='CardAjoutArticle'>
                    <div className="CardAjoutContent">
                        <form ref={refFormPrincipal} onSubmit={handleSubmit}>
                            <div className='info-article'>
                                <div className='titre'> Titre de l'article : </div>   
                                <div className='article-input-container'>
                                    <input required className='article-input input-style' type='text' name='titre' maxLength='100'/>
                                </div>           
                            </div>
                            <br/>
                            <div className='info-article'>
                                <div className='titre'><img src={iconCalendrier} alt='calendrier' className='icon-article' /> Date : </div>
                                <div className='article-input-container'>
                                    <input required className='article-input input-style' type='datetime-local' name='date' />
                                </div>
                            </div>
                            <br />
                            <div className='info-article'>
                                <div className='titre'><img src={iconMap} alt='map' className='icon-article' /> Localisation :</div>
                                <div className='article-input-container'>
                                    <input required className='article-input input-style' type='text' name='localisation' maxLength='250' />
                                </div>
                            </div>
                            <br />
                            <div className='info-article'>
                                <div className='titre'><img src={iconSiteweb} alt='site' className='icon-article' /> Site web :</div>
                                <div className='article-input-container'>
                                    <input required className='article-input input-style' type='text' name='site' maxLength='250'/>
                                </div>
                            </div>

                            <br />
                            <hr />
                            <div className='info-article'>
                                <div className='titre'> Description : </div>
                            </div>
                            {sectionsValide.map((section) => {

                                return <SectionDesc titre={section.titre} texte={section.texte} id={section.id} />

                            })
                            }
                            {sectionsAttente.map((section) => {
                                return <SectionAttenteDesc id={section.id} />
                            })
                            }
                            <AjoutSectionDesc fonctionOnClick={handleClickAjoutSection} />
                            <div className='valider-container'>
                                <input className='valider' type='submit' value="Envoyer l'évenement" />
                            </div>
                        </form>
                    </div>
                </Card>
            </div>
    )
}

export default AjoutArticle;
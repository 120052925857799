import React, {  useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Card from './Card';
import '../styles/Connexion.css';
import CircularJSON from 'circular-json';
import Cookie from 'js-cookie';



function Connexion(){
    let navigate = useNavigate();
    let dispatch = useDispatch()

    const [mdp, setMdp] = useState('')
    const [message,setMessage] = useState('Mot de passe ou email incorrect')
    const [visibiliteMessage,setVisibiliteMessage] = useState('hidden')

    const goHome = ()=> {
        dispatch({type:'menu/pageChanged',payload:{id:1,text:'accueil'}})
        navigate('/')
    }

    const goInscription = ()=> {
        dispatch({type:'menu/pageChanged',payload:{id:4,text:'inscription'}})
        navigate('/Inscription')
    }

    function handleMdpChange(e){
        setMdp(e.target.value)
    }

    function handleSubmit (event) {
        event.preventDefault()

        const hashedPassword = mdp;

        fetch(`${process.env.REACT_APP_URL_API}/utilisateur/connexion`,{
            method: 'POST',
            body: CircularJSON.stringify({
                email: event.target.elements.email.value,
                mdp: hashedPassword
            },),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        }).then((reponse) => {
            if (reponse.status === 401) {      
                setVisibiliteMessage('visible')
                throw new Error("Erreur d'authentification");
            }
            return reponse.json()})
        .then((resultat) =>{
            const expiration = new Date();
            expiration.setHours(expiration.getHours() + 1);

            Cookie.set('jwt', resultat.token, {expires: expiration})
            Cookie.set('pseudo', resultat.pseudo, {expires: expiration})
            Cookie.set('idUtilisateur', resultat.idUtilisateur, {expires: expiration})
            dispatch({type:'menu/etatConnexionChanged',payload:{id:1,text:'connecte'}})
            goHome()
        })   
    }

    return(
        <div className='Connexion'>
            <Card className='CardFormConnexion'>
                <form onSubmit={handleSubmit} className='FormConnexion'>
                    <div className='input-container'>
                        <div className='titre'> E-mail : </div>   
                        <div className='article-input-container'>
                            <input required className='article-input input-style' type='email' name='email' maxLength='50'/>
                        </div>           
                    </div>

                    <div className='input-container'>
                        <div className='titre'> Mot de passe : </div>   
                        <div className='article-input-container'>
                            <input required className='article-input input-style' type='password' name='mdp' onChange={handleMdpChange}/>
                        </div>           
                    </div>

                    <div className='boutons-connexion'>
                        <input className='bouton-connexion' type="submit" value='Connexion' />
                        <div className='AllerInscription-container'>
                            <a onClick={goInscription} className='AllerInscription'>Je n'ai pas de compte</a>
                        </div>
                    </div>
                    <div className='MessageInfo' style={{color:'red', visibility:visibiliteMessage }}>
                        {message}
                    </div>
                </form>
            </Card>
            </div>
    )
}



export default Connexion
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Card from './Card';
import "bootstrap/dist/css/bootstrap.css";
import { useLocation,useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';
import '../styles/Notification.css'

function Notification(){
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const [notifications,setNotifications] = useState([])

    function resizeTexte(t){
        let nombreMax = 80
        if(t.length > nombreMax)
            return t.substring(0,80) + "..."
        return t
    }

    useEffect(()=>{
        fetch(`${process.env.REACT_APP_URL_API}/notifications`,{
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer '+ Cookie.get('jwt')
            },
        })
        .then((reponse) => reponse.json())
        .then((resultat) =>{
            setNotifications(resultat)
        })
    },[])

    function handleVueNotification(idNotification) {
        fetch(`${process.env.REACT_APP_URL_API}/notifications/vu`,{
            method: 'PUT',
            body:JSON.stringify({
                idNotification:idNotification
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer '+ Cookie.get('jwt')
            },
        })
    }

    function goEvent(idCompetition){
        dispatch({type:'menu/pageChanged',payload:{id:2,text:'accueil'}})
        navigate('/Article',{state:{idCompetition:idCompetition}})
    }

    function dateFormat(date){
        var d = new Date(date); 
        return d.toLocaleString().substring(0,16)
    }

    return(
            <div className="Notifications">
                <div className= "ListeNotifications">
                    {notifications.map((n)=>{
                        return( 
                            <div className="Notification">
                                <Card className={n.Vu === 1 ? "CardNotification text-center transparent" : "CardNotification text-center "  }
                                    onClick={(e) =>{
                                        handleVueNotification(n.IdNotification)
                                        goEvent(n.IdCompetition)
                                    }}  
                                    key={n.IdNotification}>
                                    <Card.Body>
                                            <div className="PhraseNotification">
                                                <div className="NotificationIntro">
                                                    <strong>{n.Pseudo} </strong>  a répondu à votre message : 
                                                </div>
                                                <div className="NotificationTexte">
                                                    {resizeTexte(n.Texte)}
                                                </div>
                                            </div>
                                            <div className="InformationsNotification">
                                                <div className="NouvelleNotificationGrid">
                                                    { 
                                                        n.Vu === 0 ?
                                                        <div className="NouvelleNotification">
                                                            Nouveau
                                                        </div>
                                                        :
                                                        <div></div>
                                                    }
                                                </div>
                                                <div className="DateNotificationGrid">
                                                    <div className="DateNotification">
                                                        {dateFormat(n.DatePoste)}
                                                    </div>
                                                </div>
                                            </div>
                                    </Card.Body>
                                </Card>  
                            </div>            
                        )                
                    })}
                </div>
            </div>
    )
}

export default Notification;
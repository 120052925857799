import React, {  useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import '../styles/Profil.css';
import CircularJSON from 'circular-json';
import Cookie from 'js-cookie';
import { useNavigate } from 'react-router-dom';

function Profil(){
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const [informationsUtilisateur,setInformationUtilisateur] = useState({idUtilisateur:'',Pseudo:'',Mdp:'',Email:''})

    useEffect(() => {
        fetch(`${process.env.REACT_APP_URL_API}/utilisateur`,{
            method: 'GET',
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer '+ Cookie.get('jwt')
            },
        }).then((reponse) => {
            if (reponse.status === 401) {
                dispatch({type:'menu/pageChaned',payload:{id:1,text:'accueil'}})
                navigate('/Accueil')
                throw new Error("Erreur d'authentification")               
            }
            return reponse.json()})
        .then((resultat) =>{
           setInformationUtilisateur(resultat.results[0])
        })   
    },[])


    return(
  
            <div className='Profil'>
                <Card className='CardProfil'>
                    <div className='TitreProfil'>
                        Profil                      
                    </div>
                    <div className='profil-information'>
                        <div className='titre-information'>
                            Pseudo : 
                        </div>
                        <div className='valeur-information'>
                            {informationsUtilisateur.Pseudo}
                        </div>
                    </div>
                    <div className='profil-information'>
                        <div className='titre-information'>
                            E-mail : 
                        </div>
                        <div className='valeur-information'>
                            {informationsUtilisateur.Email}
                        </div>
                    </div>
                </Card>
            </div>
    )
}

export default Profil;
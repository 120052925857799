
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import React, {  useState, useEffect } from 'react';
import Cookie from 'js-cookie';

import '../styles/Header.css'

import iconMenu from '../images/menu.png'

function Header(){
    let navigate = useNavigate();
    let dispatch = useDispatch();

    const etatMenu = useSelector(state => state.menu.etatMenu)

    const handleClickLogo = ()=> {
        dispatch({type:'menu/pageChanged',payload:{id:1,text:'accueil'}})
        navigate('/')
        
    }

    const handleClickMenuTelephone = () =>{
        if(etatMenu.id === 1){
            dispatch({type:'menu/etatMenuChanged',payload:{id:0,text:'ferme'}})
        }          
        else{
            dispatch({type:'menu/etatMenuChanged',payload:{id:1,text:'ouvert'}})
        }
    }

    

    return(
        <header>
            <div className='Header'>
                <div className='menuTelephone' onClick={handleClickMenuTelephone}><img src={iconMenu} className='icon-menu'></img></div>
                <div className='logo' onClick={handleClickLogo}>
                    <span className='LogoStreet' style={{color:'white'}}>Event</span><span className='logoForum' style={{color:'#1B9476',fontWeight:'bold'}}>SW</span>
                </div>
                             
            </div>
        </header>
    )
}

export default Header;
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Card from 'react-bootstrap/Card';
import "bootstrap/dist/css/bootstrap.css";
import { useLocation,useNavigate } from 'react-router-dom';
import '../styles/Accueil.css'
import iconTraction from '../images/pull.png'
import iconInterrogation from '../images/help.png'
import iconMontre from '../images/future.png'

function Accueil(){
    let navigate = useNavigate();
    let dispatch =  useDispatch();

    const elem1Ref = useRef(null)
    const elem2Ref = useRef(null)
    const elem3Ref = useRef(null)
    const elem4Ref = useRef(null)
    const [ elem1Visible, setElem1Visible ] = useState(false)
    const [ elem2Visible, setElem2Visible ] = useState(false)
    const [ elem3Visible, setElem3Visible ] = useState(false)
    const [ elem4Visible, setElem4Visible ] = useState(false)

    const callbackFunction1 = (entries) => {
        const [ entry ] = entries
        setElem1Visible(entry.isIntersecting)
    }
    const callbackFunction2 = (entries) => {
        const [ entry ] = entries
        setElem2Visible(entry.isIntersecting)
    }
    const callbackFunction3 = (entries) => {
        const [ entry ] = entries
        setElem3Visible(entry.isIntersecting)
    }
    const callbackFunction4 = (entries) => {
        const [ entry ] = entries
        setElem4Visible(entry.isIntersecting)
    }
    const options = {
        root: null,
        rootMargin: "0px",
        threshold:0.2
    }

    useEffect(() => {
        
        const observer1 = new IntersectionObserver(callbackFunction1, options)
        if (elem1Ref.current) observer1.observe(elem1Ref.current)
        
    }, [elem1Ref, options])

    useEffect(() => {
        
        const observer2 = new IntersectionObserver(callbackFunction2, options)
        if (elem2Ref.current) observer2.observe(elem2Ref.current)
        
    }, [elem2Ref, options])

    useEffect(() => {
        
        const observer3 = new IntersectionObserver(callbackFunction3, options)
        if (elem3Ref.current) observer3.observe(elem3Ref.current)
        
    }, [elem3Ref, options])

    useEffect(() => {
        
        const observer4 = new IntersectionObserver(callbackFunction4, options)
        if (elem4Ref.current) observer4.observe(elem4Ref.current)
        
    }, [elem4Ref, options])

    const goEvenements = () =>{
        dispatch({type:'menu/pageChanged',payload:{id:2,texte:'evenements'}})
        navigate('/Evenements')
    }

    const goAjoutarticle = () =>{
        dispatch({type:'menu/pageChanged',payload:{id:3,texte:'ajout-article'}})
        navigate('/Ajout-article')
    }

    return(      
            <div className="Accueil">
                <ul className="ListeAccueil">
                    <li className={elem1Visible ? 'elem-apparait elem-liste-accueil' : 'elem-liste-accueil'} ref={elem1Ref}>
                        <div className="part-accueil">
                            <div className="elem-ligne"></div>
                            <div className="rond-ligne-accueil"><img className="icon-rond-accueil" src={iconTraction} /></div>
                            <div className={elem1Visible ? "Elem1Accueil sous-elem-liste-accueil sous-elem-liste-apparait" : "Elem1Accueil sous-elem-liste-accueil"} >
                                <h1 className="titre-elem-accueil">Qu'est-ce que EventSW ?</h1>
                                <div className="paragraphe-elem-accueil">
                                    Retrouve les événements de street-workout à venir
                                    <br/>
                                    Discute avec d'autres passionnés sous chaque poste
                                    <br/>
                                    Nous comptons sur toi pour ajouter des nouveaux événements
                                </div>
                                <div className="bouton-accueil-container">
                                    <div className="bouton-accueil" onClick={goEvenements}>
                                        Voir les évenements
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li  className={elem2Visible ? 'elem-apparait elem-liste-accueil' : 'elem-liste-accueil'} ref={elem2Ref}>
                        <div className="part-accueil" >
                            <div className="elem-ligne"></div>
                            <div className="rond-ligne-accueil"><img className="icon-rond-accueil" src={iconInterrogation} /></div>
                            <div className={elem2Visible ? "Elem2Accueil sous-elem-liste-accueil sous-elem-liste-apparait" : "Elem2Accueil sous-elem-liste-accueil"} >
                                <h1 className="titre-elem-accueil">Pourquoi avons-nous créé EventSW ?</h1>
                                <div className="paragraphe-elem-accueil">
                                    Nous avons remarqué que les événements étaient très peu médiatisés
                                    <br/>
                                    Il était donc dur de se tenir au courant sur les évenements à venir
                                    <br/>
                                    Notre but est donc de centraliser ces informations
                                    <br/>
                                    Et de les rendre accessibles facilement
                                </div>
                                <div className="bouton-accueil-container">
                                    <div className="bouton-accueil" onClick={goAjoutarticle}>
                                        Ajouter un évenement
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li className={elem3Visible ? 'elem-apparait elem-liste-accueil' : 'elem-liste-accueil'} ref={elem3Ref}>
                        <div className="part-accueil">
                            <div className="elem-ligne"></div>
                            <div className="rond-ligne-accueil"><img className="icon-rond-accueil" src={iconMontre} /></div>
                            <div className={elem3Visible ? "Elem3Accueil sous-elem-liste-accueil sous-elem-liste-apparait" : "Elem3Accueil sous-elem-liste-accueil"} >
                                <h1 className="titre-elem-accueil">Les changements à venir ?</h1>
                                <div className="paragraphe-elem-accueil">
                                    Des interactions avec les postes/commentaires seront possibles
                                    <br/>
                                    Des catégories seront disponibles pour les événements ainsi il sera possible de chercher en fonction de ce qui vous intéresse
                                    <br/>
                                    La possibilité de trouver du matèriel de qualité validé par la communauté !
                                </div>
                            </div>
                        </div>
                    </li>                   
                </ul>
            </div>
    )
}

export default Accueil;
import { useRef,useEffect,useState } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Card from './Card';
import ServiceCompetitions from "../services/ServiceCompetitions"

import "bootstrap/dist/css/bootstrap.css";
import '../styles/Article.css'

import iconCalendrier from '../images/calendar.png'
import iconMap from '../images/map.png'
import iconSiteweb from '../images/website.png'
import iconEnvoi from '../images/send.png'
import iconRepondre from '../images/reply.png'
import iconRetour from '../images/fleche-gauche.png'

import CircularJSON from 'circular-json';
import Cookie from 'js-cookie';
import ClientWS from '../Client';
import Button from 'react-bootstrap/Button';
import Modal from './Modal';

const clientWS = ClientWS.getInstance();

function Article(){
    let navigate = useNavigate();
    let location = useLocation();
    let dispatch = useDispatch();
    let state = location.state;

    const [article,setArticle] = useState({IdCompetition:'',Titre:'',DateCompetition:'',Localisation:'',Siteweb:''});
    const [description,setDescription] = useState([])
    const [discussions,setDiscussions] = useState({count:0,messages:[]})
    const [idReponseOuverte,setIdReponseOuverte] = useState(0)
    const [voirModalConnexion,setVoirModalConnexion] = useState(false)

    const formRef = useRef(null);
    const formReponseRef = useRef(null)

    useEffect(()=>{
        ServiceCompetitions.getCompetition(state.idCompetition)
        .then((resultat) =>{
            setArticle(resultat.data.competition[0])
            setDescription(resultat.data.description)
        })

        recupMessage()
    },[state])


    /*useEffect(() => {
        clientWS.getWs().onmessage = (message) => {
            let messageJson = JSON.parse(message.data)
            console.log(messageJson)
            console.log(state.idCompetition)
            if(messageJson.msg === 'Message' && messageJson.idCompetition === state.idCompetition.toString()){
                console.log('recup messages')
                recupMessage()
            }
        }
    })*/

    function recupMessage() {
        fetch(`${process.env.REACT_APP_URL_API}/competition/messages?idCompetition=`+state.idCompetition)
        .then((reponse) => reponse.json())
        .then((resultat) =>{
            console.log(resultat)
            setDiscussions(resultat)
        })
    }

    const handleEnvoiMessage = (event) =>{
        event.preventDefault()
        envoyerMessage(event.target.elements.commentaire.value,article.IdCompetition,null,null,false)
    }
    
    function handleEnvoiReponse (event,idMessage,idReponseUtilisateur) {
        event.preventDefault()
        envoyerMessage(event.target.elements.commentaire.value,article.IdCompetition,idMessage,idReponseUtilisateur,true)
       
    }

    function envoyerMessage(texte,idCompetition,idMessage,idReponseUtilisateur,estUneReponse){
        fetch(`${process.env.REACT_APP_URL_API}/messages/creation`,{
            method: 'POST',
            body: CircularJSON.stringify({
                texte: texte,
                idCompetition: idCompetition,
                idMessageReponse:idMessage,
                idReponseUtilisateur:idReponseUtilisateur
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': 'Bearer '+ Cookie.get('jwt')
            },
        }).then((reponse) => {
            if (reponse.status === 401) {
                setVoirModalConnexion(true)
                throw new Error("Erreur d'authentification")               
            }
            return reponse.json()})
        .then((resultat) =>{
            if(estUneReponse){               
                setIdReponseOuverte(0)
                formReponseRef.current.reset()
                
            }
            else{
                formRef.current.reset()
            }
            recupMessage()
            
        })   
    }

    const handleFermerModalConnexion = () => {
        setVoirModalConnexion(false)
    }

    const handleAllerPageConnexion = () =>{
        dispatch({type:'menu/pageChanged',payload:{id:5,text:'connexion'}})
        navigate('/Connexion')
    }

    const handleAllerPageEvenements = () =>{
        navigate('/Evenements')
    }

    function blobToImage (blob){
        return new Promise(resolve => {
          const url = URL.createObjectURL(new Blob(blob))
          let img = new Image()
          img.onload = () => {
            URL.revokeObjectURL(url)
            resolve(img)
          }
          img.src = url
        })
    }

    function dateFormat(date){
        var d = new Date(date); 
        return d.toLocaleString().substring(0,16)
    }

    return(
           
            <div className="Article">
                <Modal show={voirModalConnexion}>                   
                    <Modal.Titre>Vous n'êtes pas connecté</Modal.Titre>                  
                    <Modal.Body >
                        Vous devez être connecter pour envoyer un message
                        <div className='boutons-modal'>
                            <div className='bouton-modal boutonModalFermer' onClick={handleFermerModalConnexion}>
                                Fermer
                            </div>
                            <div className='bouton-modal boutonModalConnecter' onClick={handleAllerPageConnexion}>
                                Se connecter
                            </div>
                        </div>
                        
                    </Modal.Body>
                </Modal>
                <div className='ArticleBody'>
                    <Card className='CardArticle'>
                        <Card.Body>
                                <div className='FlecheArticle' onClick={handleAllerPageEvenements}>
                                    <img src={iconRetour} alt='fleche' className='icon-article'/>
                                    
                                </div>
                                <div className='GrosTitre'>{article.Titre}</div>
                                <br/>
                                <div className='info-article'>
                                    <div className='titre'><img src={iconCalendrier} alt='calendrier' className='icon-article'/> Date : </div> 
                                    <div className='dateArticle'>{dateFormat(article.DateCompetition)}</div>                
                                </div>
                                <br/>
                                <div className='info-article'>
                                    <div className='titre'><img src={iconMap} alt='map' className='icon-article'/> Localisation :</div>
                                    <div className='localisationArticle'><a href={'https://www.google.fr/maps/place/'+article.Localisation} >{article.Localisation}</a> </div>
                                </div>
                                <br/>
                                <div className='info-article'>
                                    <div className='titre'><img src={iconSiteweb} alt='site' className='icon-article'/> Site web :</div>
                                    <div className='sitewebArticle'><a href={article.Siteweb} target="_blank">{article.Siteweb}</a></div>
                                </div>
                               
                                <br/>
                                <hr/>
                                <div className='info-article description'>
                                    <div className='titre'> Description : </div>
                                    <div className='descriptionArticle'>
                                        {description.map((d)=>{
                                            return(
                                                <div className='description-section'>
                                                    <div className='section-titre'>{d.titre}</div>
                                                    <div className='section-texte'>
                                                        <code>{d.texte}</code>
                                                    </div>
                                                </div>
                                            )
                                            
                                        })} 
                                    </div>
                                </div>
                                <br/>
                        </Card.Body>
                    </Card>
                </div>
                <div className='ArticleMessages'>
                    <Card className='CardDiscussionArticle'>
                        <div className='titre info-article'>{discussions.count} commentaires </div>
                        <form className='formulaireMessage' ref = {formRef} onSubmit={handleEnvoiMessage}>
                            <textarea required name='commentaire' type="text" rows="3" placeholder='Ajoutez un commentaire' maxLength='400' className='inputMessage input-style'/>
                            <button variant="primary" type="submit" className='boutonMessage'>
                                <img src={iconEnvoi} className='icon-button'/>
                            </button>
                        </form>
                        {discussions.messages.map((d) =>{
                            return(
                                <div className='messageArticle' key={d.message.IdMessage}>
                                    <div className='pseudoDateMessage'>
                                        <div className='pseudoMessage'>{d.message.Pseudo}</div>
                                        <div className='dateMessage'>, le {dateFormat(d.message.DatePoste)}</div>
                                    </div>                                   
                                    <div className='texteMessage'><code>{d.message.Texte}</code></div>
                                    <div className='interactionsMessage'>
                                        <div className='interaction-message'>
                                            <img src={iconRepondre} className='icon-interaction' />
                                            <div onClick={() => setIdReponseOuverte(d.message.IdMessage)}>Répondre</div>
                                        </div>
                                    </div>
                                    <div className='reponsesMessage'>
                                        {d.message.IdMessage === idReponseOuverte ?
                                                    <div>
                                                        <form className='formulaireMessage' ref = {formReponseRef} onSubmit={(event) => {handleEnvoiReponse(event,d.message.IdMessage,d.message.IdUtilisateur)}}>
                                                            <textarea required autoFocus name='commentaire' type="text" rows="3" placeholder='Ajoutez une réponse' maxLength='400' className='inputMessage input-style'/>
                                                            <button variant="primary" type="submit" className='boutonMessage'>
                                                                <img src={iconEnvoi} className='icon-button'/>
                                                            </button>
                                                        </form>
                                                    </div>
                                                    :
                                                    <div></div>
                                        }
                                        {d.reponses.map((r)=>{
                                            return(
                                                <div className='reponseMessage' key={r.IdMessage}>
                                                    <div className='pseudoDateMessage'>
                                                        <div className='pseudoMessage'>{r.Pseudo}</div>
                                                        <div className='dateMessage'>, le {dateFormat(r.DatePoste)}</div>
                                                    </div>                                   
                                                    <div className='texteMessage'><code>{r.Texte}</code></div>
                                                    <div className='interactionsMessage'>
                                                        <div className='interaction-message'>
                                                            <img src={iconRepondre} className='icon-interaction' />
                                                            <div onClick={() => setIdReponseOuverte(r.IdMessage)}>Répondre</div>
                                                        </div>
                                                    </div>
                                                    {r.IdMessage === idReponseOuverte ?
                                                    <div>
                                                        <form className='formulaireMessage' ref = {formReponseRef} onSubmit={(event) => {handleEnvoiReponse(event,d.message.IdMessage,r.IdUtilisateur)}}>
                                                            <textarea required autoFocus name='commentaire' type="text" rows="3" placeholder='Ajoutez une réponse' maxLength='400' className='inputMessage input-style' >{'@' + r.Pseudo + ' '}</textarea>
                                                            <button variant="primary" type="submit" className='boutonMessage'>
                                                                <img src={iconEnvoi} className='icon-button'/>
                                                            </button>
                                                        </form>
                                                    </div>
                                                    :
                                                    <div></div>
                                                    }
                                                </div>
                                                
                                            )
                                        })}
                                    </div>
                                </div>

                            )
                        })}
                    </Card>
                </div>
            </div>
    )
}

export default Article;
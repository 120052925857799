import './App.css';
import Client from './Client.js';
import {Navigate,Routes,Route} from 'react-router-dom'
import Header from './composants/Header'
import NavSide from './composants/NavSide'
import AccueilComposant from './composants/AccueilComposant'
import EvenementsComposant from './composants/EvenementsComposant'
import ArticleComposant from './composants/ArticleComposant'
import ProfilComposant from './composants/ProfilComposant'
import NotificationsComposant from './composants/NotificationsComposant'
import ConnexionComposant from './composants/ConnexionComposant'
import InscriptionComposant from './composants/InscriptionComposant'
import AjoutArticleComposant from './composants/AjoutArticleComposant'
import VerificationComposant from './composants/VerificationComposant'

const client = Client.getInstance();

function App() {
  return (
      <div className='BasePage'>
        <Header/>
        <div className='PageAffichage'>
          <NavSide/>
          <Routes>
            <Route exact path="/" element={<AccueilComposant/>}/>
            <Route exact path="/Evenements" element={<EvenementsComposant/>}/>
            <Route exact path="/Article" element={<ArticleComposant/>}/>
            <Route exact path="/Ajout-article" element={<AjoutArticleComposant/>}/>
            <Route exact path="/Profil" element={<ProfilComposant/>}/>
            <Route exact path="/Notifications" element={<NotificationsComposant/>}/>
            <Route exact path="/Connexion" element={<ConnexionComposant/>}/>
            <Route exact path="/Inscription" element={<InscriptionComposant/>}/>
            <Route exact path="/Validation" element={<VerificationComposant/>}/>
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>       
      </div>
  ) 
}

export default App;

const url = process.env.REACT_APP_URL_WS;
let ws = new WebSocket(url);
ws.onopen = () => {
    console.log('WebSocket Client Connected ' + url);
};

let instance;


class Client{

    constructor() {
        if (instance) {
          throw new Error("You can only create one instance!");
        }
        instance = this;
      }

    getInstance() {
        return this
    }

    getWs(){
        return ws
    }
}

const singletonClient = Object.freeze(new Client());
export default singletonClient;